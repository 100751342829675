<template>
  <layout>
    <template slot="main">
      <v-card class="mt-6">
        <v-card-title>
          Key Oluştur
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              tetikle = true;
              gelen_id = null;
            "
          >
            Yeni Lisans
          </v-btn>
        </v-card-title>
        <v-col cols="12" sm="7" md="5">
          <v-text-field
            v-model="gelenKod"
            label="KeyCode"
            name="ad"
            id="ad"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="tarih"
            label="YYYY-AA-GG"
            name="ad"
            id="ad"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="yazilan_gun"
            type="number"
            label="Gün"
            name="ad"
            id="ad"
            outlined
          ></v-text-field>
          <v-btn depressed color="primary" @click="tersine()"> Oluştur </v-btn>
        </v-col>
        <v-text-field
          v-model="sonuc"
          label="Sonuç"
          name="ad"
          id="ad"
          outlined
        ></v-text-field>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: { Layout },

  async mounted() {},

  methods: {
    tersine() {
      let cozulen_bilgiler = [];
      cozulen_bilgiler = [];
      const uclu_parcalar = this.gelenKod.split("-");
      const anahtar = uclu_parcalar[4].toString();
      const anahtar_sayi = parseInt(anahtar, 16);
      let ilk_uclu_asil = "";
      for (let i = 0; i < uclu_parcalar[0].toString().length; i++) {
        const sayi = parseInt(uclu_parcalar[0].toString()[i].toString(), 16);
        const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
        const sayi_ikinci_mod =
          sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
        ilk_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
      }

      let ikinci_uclu_asil = "";
      for (let i = 0; i < uclu_parcalar[1].toString().length; i++) {
        const sayi = parseInt(uclu_parcalar[1].toString()[i].toString(), 16);
        const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
        const sayi_ikinci_mod =
          sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
        ikinci_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
      }

      let ucuncu_uclu_asil = "";
      for (let i = 0; i < uclu_parcalar[3].toString().length; i++) {
        const sayi = parseInt(uclu_parcalar[3].toString()[i].toString(), 16);
        const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
        const sayi_ikinci_mod =
          sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
        ucuncu_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
      }

      let otuzgun_sezar_asil = "";
      for (let i = 0; i < uclu_parcalar[2].toString().length; i++) {
        const sayi = parseInt(uclu_parcalar[2].toString()[i].toString(), 16);
        const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
        const sayi_ikinci_mod =
          sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
        otuzgun_sezar_asil += sayi_ikinci_mod.toString(16).toUpperCase();
      }

      cozulen_bilgiler.push(
        ilk_uclu_asil +
          "-" +
          ikinci_uclu_asil +
          "-" +
          otuzgun_sezar_asil +
          "-" +
          ucuncu_uclu_asil +
          "-" +
          anahtar
      );
      cozulen_bilgiler.push(parseInt(otuzgun_sezar_asil, 16).toString());
      this.tarih_farki_hesapla();
      const deneme = this.yeni_key_olustur(
        cozulen_bilgiler[0],
        this.olusan_gun_sayisi
      );
      this.sonuc = deneme;
    },
    yeni_key_olustur(gelen_kod, kac_gun) {
      let ucluler = "";
      let ucluler_asil = "";
      const parcala = gelen_kod.split("-");
      const ilk_uclu = parcala[0];
      const ikinci_uclu = parcala[1];
      const ucuncu_uclu = parcala[3];
      const otuz_gun = kac_gun.toString(16).toUpperCase().padStart(3, "0");

      while (ucluler_asil === ucluler) {
        const rastgele_sayi = Math.floor(Math.random() * 999999999) + 9999;
        const rastgele_hex = rastgele_sayi.toString(16).toUpperCase();
        const rastgele_uclu = rastgele_hex.substring(0, 3);
        const revize_rastgele_sayi = parseInt(rastgele_uclu, 16);

        ucluler_asil = `${ilk_uclu}-${ikinci_uclu}-${otuz_gun}-${ucuncu_uclu}-${rastgele_uclu}`;

        let ilk_uclu_sezar = "";
        for (let i = 0; i < ilk_uclu.length; i++) {
          const sayi = parseInt(ilk_uclu[i], 16);
          ilk_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let ikinci_uclu_sezar = "";
        for (let i = 0; i < ikinci_uclu.length; i++) {
          const sayi = parseInt(ikinci_uclu[i], 16);
          ikinci_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let ucuncu_uclu_sezar = "";
        for (let i = 0; i < ucuncu_uclu.length; i++) {
          const sayi = parseInt(ucuncu_uclu[i], 16);
          ucuncu_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let otuz_gun_sezar = "";
        for (let i = 0; i < otuz_gun.length; i++) {
          const sayi = parseInt(otuz_gun[i], 16);
          otuz_gun_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        ucluler = `${ilk_uclu_sezar}-${ikinci_uclu_sezar}-${otuz_gun_sezar}-${ucuncu_uclu_sezar}-${rastgele_uclu}`;
      }
      return ucluler;
    },
    tarih_farki_hesapla() {
      // console.log("geldim");
      // const gunun_tarihi = moment();
      // const baslangic_tarihi = moment(this.tarih);
      // const fark = gunun_tarihi.diff(baslangic_tarihi, "days");
      // console.log(fark);
      //parseInt(fark) +
      this.olusan_gun_sayisi = parseInt(this.yazilan_gun);
    },
  },

  data() {
    return {
      gelenKod: "",
      yazilan_gun: 0,
      tarih: null,
      sonuc: null,
      olusan_gun_sayisi: 0,
    };
  },
};
</script>
